import { inject, Injectable, signal } from '@angular/core';
import { UserInterface } from '@my-toolbox-fe/type-user';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '@my-toolbox-fe/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  currentUserSig = signal<UserInterface | undefined | null>(undefined);
  private http = inject(HttpClient);

  async getUser() {
    if (this.currentUserSig()) return this.currentUserSig();

    const currentUser = (await firstValueFrom(
      this.http.get(`${environment.baseUrl}/userinfo`),
    )) as UserInterface;
    this.currentUserSig.set(currentUser);
    return this.currentUserSig();
  }
}
