import {
  Directive,
  inject,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { RolesService } from '../services/roles.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[roles]',
  standalone: true,
})
export class RolesDirective implements OnInit {
  @Input() public roles: string[] = [];
  @Input() public type = 'roles';
  hasView = false;

  /**
   * @param rolesService
   * @param {ViewContainerRef} viewContainerRef -- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef -- the templateRef to be potentially rendered
   */

  private rolesService = inject(RolesService);
  private viewContainerRef = inject(ViewContainerRef);
  private templateRef = inject(TemplateRef<any>);

  ngOnInit() {
    this.rolesService.hasPermissions(this.roles).then((hasPermission) => {
      if (hasPermission && !this.hasView) {
        // appends the ref element to DOM
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (!hasPermission && this.hasView) {
        this.viewContainerRef.clear();
        this.hasView = false;
      }
    });
  }
}
