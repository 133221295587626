export const codesLanguages: { [key: string]: string } = {
  AR: 'Arabic',
  BG: 'Bulgarian',
  ZH: 'Chinese',
  HR: 'Croatian',
  CS: 'Czech',
  DA: 'Danish',
  NL: 'Dutch',
  EN: 'English',
  ET: 'Estonian',
  FI: 'Finnish',
  FR: 'French',
  DE: 'German',
  EL: 'Greek',
  HU: 'Hungarian',
  IT: 'Italian',
  JA: 'Japanese',
  KO: 'Korean',
  LV: 'Latvian',
  LT: 'Lithuanian',
  MK: 'Macedonian',
  MT: 'Maltese',
  NO: 'Norwegian',
  PL: 'Polish',
  PT: 'Portuguese',
  RO: 'Romanian',
  RU: 'Russian',
  SR: 'Serbian',
  SK: 'Slovak',
  SL: 'Slovenian',
  ES: 'Spanish',
  SV: 'Swedish',
  TR: 'Turkish',
  UK: 'Ukrainian',
};
