import {
  computed,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from '@angular/core';
import { environment } from '@my-toolbox-fe/environments/environment';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Machine, PimProduct } from '@my-toolbox-fe/type-search';
import { SearchService } from '../search.service';
import { AlertService } from '@my-toolbox-fe/ui-elements';

@Injectable({
  providedIn: 'root',
})
export class SearchSerialService {
  pimProducts: WritableSignal<PimProduct[] | []> = signal([]);
  protected searchService = inject(SearchService);
  protected alertService = inject(AlertService);
  private http = inject(HttpClient);
  #machines: WritableSignal<Machine[]> = signal([]);
  machines = computed(this.#machines);

  //step 1
  async getMachines(serialNumber?: string) {
    return await firstValueFrom(
      this.http.get(
        `${environment.baseUrl}/machines?serialNumber=${serialNumber}`,
      ),
    ).then((res) => {
      const result = <Machine[] | []>res;
      this.#machines.set(result);
      return this.machines();
    });
  }

  //step 2
  async getPimProduct(machineNumber?: string, machineProductGroup?: string) {
    return await firstValueFrom(
      this.http.get(
        `${environment.baseUrl}/facets/product?machineNumber=${machineNumber}&productGroupCode=${machineProductGroup}`,
      ),
    ).then((res) => {
      const result = <PimProduct[] | []>res;
      this.pimProducts.set(result);
      return this.pimProducts();
    });
  }
}
