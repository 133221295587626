import { computed, inject, Injectable, signal } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  SavedSearchValues,
  SEARCH_MODE,
  SearchedValues,
  SearchResult,
  Sorting,
  SORTING,
  SortOptions,
} from '@my-toolbox-fe/type-search';
import { environment } from '@my-toolbox-fe/environments/environment';
import { Pagination } from '@my-toolbox-fe/type-pagination';
import { buildQuery } from '../utils/queries';
import { FiltersService } from './filters.service';
import { replaceSpaceWithUnderscore } from '../utils/helpers';
import { FacetsService } from './facets.service';
import { Filter } from '@my-toolbox-fe/type-filter';
import { Asset } from '@my-toolbox-fe/type-asset';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  #searchResults = signal<Asset[] | []>([]);
  searchResults = computed(this.#searchResults);

  #pagination = signal<Pagination>({
    currentPage: 0,
    pageSize: 0,
    sort: '',
    totalPages: 1,
    totalResults: 0,
  });
  pagination = computed(this.#pagination);

  #sorting = signal<Sorting>({
    sortDirection: SORTING.DEFAULT,
  });
  sorting = computed(this.#sorting);

  #searchMode = signal(SEARCH_MODE.DEFAULT.value);
  searchMode = computed(this.#searchMode);

  #sortingOptions = signal<SortOptions[]>([]);
  sortingOptions = computed(this.#sortingOptions);

  isSearching = signal(false);

  #lastQuery = signal<string>('');
  lastQuery = computed(this.#lastQuery);

  #searchValues = signal<SearchedValues>({
    freeTextSearch: '',
    filters: {
      brand: [{ code: '', name: '', value: '' }],
      category: [{ code: '', name: '', value: '' }],
      asset_audience: [{ code: '', name: '', value: '' }],
      language: [{ code: '', name: '', value: '' }],
      pimProduct: [{ code: '', name: '', value: '' }],
      serviceBulletinClassification: [{ code: '', name: '', value: '' }],
      type: [{ code: '', name: '', value: '' }],
      status: [{ code: '', name: '', value: '' }],
    },
    selectedFilters: {
      brand: [],
      category: [],
      asset_audience: false,
      language: [],
      serviceBulletinClassification: [],
      type: [],
      status: ['Active'],
    },
  });

  // ACTIVE FIXEN ????
  searchValues = computed(this.#searchValues);

  private http = inject(HttpClient);
  private facetsService = inject(FacetsService);
  private filtersService = inject(FiltersService);

  async search(
    savedSearchValues?: SavedSearchValues,
    options?: {
      pagination?: Pagination;
      sorting?: Sorting;
      searchMode?: string;
      isRefinedSearch?: boolean;
    },
  ) {
    this.isSearching.set(true);
    const facets = this.facetsService.selectedFacets;
    let queryParams: HttpParams | undefined = undefined;

    if (savedSearchValues) {
      this.setSearchValues(savedSearchValues);
    }

    if (options?.sorting) {
      this.#sorting.set(options?.sorting);
    }

    if (options?.searchMode) {
      this.#searchMode.set(options?.searchMode);
    }

    queryParams = buildQuery(this.searchValues(), {
      pagination: options?.pagination,
      sorting: this.sorting(),
      facets: facets(),
      searchMode: this.#searchMode(),
      isRefinedSearch: options?.isRefinedSearch,
    });

    this.#lastQuery.set(queryParams?.get('query') || '');

    return await firstValueFrom(
      this.http.get(`${environment.baseUrl}/assets/search`, {
        params: queryParams,
      }),
    )
      .then((res) => {
        this.UpdateSearchResults(res as SearchResult);

        return res as SearchResult;
      })
      .finally(() => {
        this.isSearching.set(false);
      });
  }

  setIsSearching(value: boolean) {
    this.isSearching.set(value);
  }

  setSavedSearchValues(values: { facets: any; freeText: string }) {
    let filters: any;

    if (!values.facets.length) {
      filters = [];
    } else {
      values.facets.forEach((facet: any) => {
        let code = facet.code;
        if (code === 'content_types') {
          code = 'type';
        }

        filters = {
          ...filters,
          [code]: facet.values.map((value: any) => {
            if (facet.code === 'asset_audience') {
              return value.value.length > 0;
            }
            return replaceSpaceWithUnderscore(decodeURI(value.value));
          }),
        };
      });
    }

    this.#searchValues.set({
      ...this.searchValues(),
      freeTextSearch: values.freeText.split('=')[1],
      selectedFilters: {
        ...filters,
        serviceBulletinClassification: filters.sb_classification,
      },
    });
  }

  async clearSearch() {
    this.#searchValues.set({
      freeTextSearch: '',
      filters: {
        category: [],
        type: [],
        language: [],
        brand: [],
      },
      selectedFilters: {
        category: [],
        type: [],
        language: [],
        brand: [],
      },
    });
    return await this.search();
  }

  async searchWithQuery(query: string, queryContext: string) {
    const httpParams = new HttpParams({
      fromString: query,
    }).append('searchQueryContext', queryContext);

    this.#searchMode.set(queryContext);
    this.selectSearchMode(queryContext);

    return await firstValueFrom(
      this.http.get(`${environment.baseUrl}/assets/search`, {
        params: httpParams,
      }),
    ).then((res) => {
      this.UpdateSearchResults(res as SearchResult);
      return res as SearchResult;
    });
  }

  setSorting(direction: string) {
    this.#sorting.set({
      sortDirection: direction,
    });
  }

  setPimProduct(values: Filter[]) {
    this.#searchValues.set({
      ...this.searchValues(),
      filters: {
        pimProduct: values,
      },
    });
  }

  selectSearchMode(selectedSearchMode: string) {
    this.#searchMode.set(selectedSearchMode);
    switch (this.searchMode()) {
      case SEARCH_MODE.SERVICE_BULLETIN.value:
        this.searchValues().selectedFilters.category = [SEARCH_MODE.SERVICE_BULLETIN.category];
        this.searchValues().selectedFilters.type = [SEARCH_MODE.SERVICE_BULLETIN.type];
        this.#searchMode.set(SEARCH_MODE.SERVICE_BULLETIN.value);
        break;

      default:
        this.searchValues().selectedFilters.type = [];
        this.searchValues().selectedFilters.category = [];
        break;
    }
  }

  setGlobalUser() {
    const searchValues = {
      ...this.searchValues(),
      filters: {
        ...this.searchValues().filters,
        asset_audience: [SEARCH_MODE.GLOBAL_USER],
      },
      selectedFilters: {
        ...this.searchValues().selectedFilters,
        asset_audience: true,
      },
    };

    this.#searchValues.set(searchValues);
  }

  private setSearchValues(savedSearchValues: SavedSearchValues) {
    const val: SearchedValues = {
      freeTextSearch: savedSearchValues.freeTextSearch,
      filters: {
        ...this.searchValues().filters,
        asset_audience: savedSearchValues.asset_audience
          ? [SEARCH_MODE.GLOBAL_USER]
          : [SEARCH_MODE.NOT_GLOBAL_USER],
        category: this.filtersService.getSelectedCategories(savedSearchValues.category),
        type: this.filtersService.getSelectedContentTypes(savedSearchValues.contentType),
        language: this.filtersService.getSelectedLanguages(savedSearchValues.language),
        brand: this.filtersService.getSelectedBrands(savedSearchValues.brand),
        serviceBulletinClassification:
          this.filtersService.getSelectedServiceBulletinClassifications(
            savedSearchValues.serviceBulletinClassification,
          ),
        status: this.filtersService.getSelectedStatus(savedSearchValues.status),
      },
      selectedFilters: {
        ...this.searchValues().selectedFilters,
        asset_audience: !!savedSearchValues.asset_audience,
        category: this.filtersService.getFilterValue(savedSearchValues.category),
        type: this.filtersService.getFilterValue(savedSearchValues.contentType),
        language: this.filtersService.getFilterValue(savedSearchValues.language),
        brand: this.filtersService.getFilterValue(savedSearchValues.brand),
        serviceBulletinClassification: this.filtersService.getFilterValue(
          savedSearchValues.serviceBulletinClassification,
        ),
        status: this.filtersService.getFilterValue(savedSearchValues.status),
      },
    };

    this.#searchValues.set(val);
  }

  private UpdateSearchResults(res: SearchResult) {
    const searchResults = <SearchResult>res;
    this.#pagination.set(searchResults.pagination);

    const sortOptions = searchResults.sorts.map((item) => {
      return {
        name: item.code,
        value: item.code,
      };
    });

    const selectedFacets = searchResults.facets.flatMap((facet) => {
      return facet.values.filter((value) => value.selected);
    });

    this.#sortingOptions.set(sortOptions);
    this.facetsService.setFacets(searchResults.facets);
    this.facetsService.setSelectedFacets(selectedFacets);
    this.#searchResults.set(searchResults.results);
  }
}
