import { HttpInterceptorFn } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { inject } from '@angular/core';
import { AuthenticationResult, SilentRequest } from '@azure/msal-browser';
import { tap, from, switchMap } from 'rxjs';

const TOKEN_REFRESH_TIME = 10; // Minutes before expiration to refresh the token

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const msalService = inject(MsalService);

  const activeAccount = msalService.instance.getActiveAccount();
  let token = activeAccount?.idToken;
  const exp_old = activeAccount?.idTokenClaims?.exp as number;
  const old_token_date = new Date(exp_old * 1000);

  if (isOlderThen(old_token_date, new Date(), TOKEN_REFRESH_TIME) && activeAccount) {
    const silentRequest: SilentRequest = {
      account: activeAccount,
      scopes: ['User.Read'], // Adjust scopes as necessary
    };

    return from(msalService.instance.acquireTokenSilent(silentRequest)).pipe(
      tap((response: AuthenticationResult) => {
        token = response.idToken;
      }),
      switchMap(() => {
        return processRequest(req, token, next);
      }),
    );
  } else {
    return processRequest(req, token, next);
  }
};

const processRequest = (req: any, token: string | undefined, next: any) => {
  if (req.body instanceof FormData) {
    req = req.clone({
      setHeaders: {
        withCredentials: 'true',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${token}`,
      },
    });
  } else {
    req = req.clone({
      setHeaders: {
        withCredentials: 'true',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return next(req);
};

const isOlderThen = (d1: Date, d2: Date, min: number) => {
  const t1 = d1.getTime();
  const t2 = d2.getTime();

  const diff = Math.abs(Math.round((t1 - t2) / 1000 / 60));
  return diff < 60 - min;
};
