import { inject, Injectable } from '@angular/core';
import { UserService } from '@my-toolbox-fe/data-access-auth';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private userService = inject(UserService);

  async hasPermissions(enabledRoles: string[]) {
    await this.userService.getUser();
    const roles = this.getRolesForCurrentUser();

    if (!roles) return false;

    let idx = false;

    roles.findIndex((role) => {
      if (enabledRoles.indexOf(role) !== -1) {
        idx = true;
      }
    });
    return idx;
  }

  private getRolesForCurrentUser() {
    const roles = this.userService.currentUserSig()?.roles ?? [];
    return [...roles];
  }
}
