import { computed, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { firstValueFrom, map, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@my-toolbox-fe/environments/environment';
import { Filter } from '@my-toolbox-fe/type-filter';
import {
  replaceSpaceWithUnderscore,
  sortAlphabetically,
  sortOnNameAlphabetically,
} from '../utils/helpers';
import { codesBrands, codesLanguages, SelectedFilters } from '@my-toolbox-fe/type-search';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  hasFilterValuesLoaded = signal<boolean>(false);
  selectedFilters: WritableSignal<SelectedFilters> = signal({
    brand: [],
    category: [],
    asset_audience: false,
    language: [],
    serviceBulletinClassification: [],
    type: [],
    status: [],
  });
  allCategories: WritableSignal<Filter[]> = signal([]);
  allContentTypes: WritableSignal<Filter[]> = signal([]);
  #filterSignals: WritableSignal<{
    brands: WritableSignal<Filter[]>;
    categories: WritableSignal<Filter[]>;
    contentTypes: WritableSignal<Filter[]>;
    global: WritableSignal<Filter[]>;
    languages: WritableSignal<Filter[]>;
    serviceBulletinClassifications: WritableSignal<Filter[]>;
    status: WritableSignal<Filter[]>;
  }> = signal({
    brands: signal([]),
    categories: signal([]),
    contentTypes: signal([]),
    global: signal([]),
    languages: signal([]),
    serviceBulletinClassifications: signal([]),
    status: signal([
      { code: 'status', name: 'Active', value: 'Active' },
      { code: 'status', name: 'Archived', value: 'Archived' },
    ]),
  });
  filterSignals = computed(this.#filterSignals);
  private http = inject(HttpClient);

  async getContentTypes() {
    const url = `${environment.baseUrl}/properties/content_types/values`;
    return firstValueFrom(
      this.http.get(url).pipe(
        map((res) => {
          const data = res as Filter[];
          return data.map((item) => {
            return {
              categoryId: item.categoryId,
              code: item.code,
              value: replaceSpaceWithUnderscore(item.value),
              name: item.name,
            };
          });
        }),
        map((data) => data.sort(sortAlphabetically)),
        tap((contentTypes) => {
          this.filterSignals().contentTypes.set(contentTypes);
          this.allContentTypes.set(contentTypes);
        }),
      ),
    );
  }

  updateContentTypes(contentTypes: string[]) {
    if (contentTypes.length === 0) {
      this.filterSignals().contentTypes.set(this.allContentTypes());
      return;
    }
    this.filterSignals().contentTypes.update(() => {
      return contentTypes
        .map((category) => {
          return this.allContentTypes().filter((contentType) => {
            return contentType.categoryId === Number(category);
          });
        })
        .flat();
    });
  }

  updateSelectedContentTypes(contentTypes: string[]) {
    this.selectedFilters.set({ ...this.selectedFilters(), type: contentTypes });
  }

  updateSelectedLanguages(languages: string[]) {
    this.selectedFilters.set({
      ...this.selectedFilters(),
      language: languages,
    });
  }

  async getServiceBulletinClassifications() {
    const url = `${environment.baseUrl}/properties/sb_classification/values`;
    return firstValueFrom(
      this.http.get(url).pipe(
        map((res) => {
          const data = res as Filter[];
          return data.map((item) => {
            return {
              categoryId: item.categoryId,
              code: item.code,
              value: replaceSpaceWithUnderscore(item.value),
              name: item.name,
            };
          });
        }),
        map((data) => data.sort(sortAlphabetically)),
        tap((serviceBulletinClassifications) => {
          this.filterSignals().serviceBulletinClassifications.set(serviceBulletinClassifications);
        }),
      ),
    );
  }

  getSelectedContentTypes(val?: string[]) {
    return val?.flatMap((item) => {
      return this.filterSignals()
        .contentTypes()
        .filter((e) => {
          return e.value === item;
        });
    });
  }

  async getLanguages() {
    return firstValueFrom(
      this.http.get(`${environment.baseUrl}/properties/language/values`).pipe(
        map((res) => {
          const data = res as Filter[];
          return data.map((item) => {
            return {
              code: item.code,
              value: replaceSpaceWithUnderscore(item.value),
              name: codesLanguages[item.name],
            };
          });
        }),
        map((data) => data.sort(sortOnNameAlphabetically)),
        tap((languages) => this.filterSignals().languages.set(languages)),
      ),
    );
  }

  getSelectedLanguages(val?: string[]) {
    return val?.flatMap((item) => {
      return this.filterSignals()
        .languages()
        .filter((e) => {
          return e.value === item;
        });
    });
  }

  async getBrands() {
    return firstValueFrom(
      this.http.get(`${environment.baseUrl}/properties/brand/values`).pipe(
        map((res) => {
          const data = res as Filter[];
          return data.map((item) => {
            return {
              code: item.code,
              value: replaceSpaceWithUnderscore(item.value),
              name: codesBrands[item.value] || item.value,
            };
          });
        }),
        map((data) => data.sort(sortOnNameAlphabetically)),
        tap((brands) => this.filterSignals().brands.set(brands)),
      ),
    );
  }

  getSelectedBrands(val?: string[]) {
    return val?.flatMap((item) => {
      return this.filterSignals()
        .brands()
        .filter((e) => {
          return e.value === item;
        });
    });
  }

  async getCategories() {
    return firstValueFrom(
      this.http.get(`${environment.baseUrl}/properties/category/values`).pipe(
        map((res) => {
          const data = res as Filter[];
          return data.map((item) => {
            return {
              code: item.code,
              value: replaceSpaceWithUnderscore(item.value),
              name: item.name,
            };
          });
        }),
        map((data) => data.sort(sortAlphabetically)),
        tap((categories) => {
          this.filterSignals().categories.set(categories);
          this.allCategories.set(categories);
        }),
      ),
    );
  }

  getSelectedCategories(val?: string[]) {
    return val?.flatMap((item) => {
      return this.filterSignals()
        .categories()
        .filter((e) => {
          return e.value === item;
        });
    });
  }

  getSelectedServiceBulletinClassifications(val?: string[]) {
    return val?.flatMap((item) => {
      return this.filterSignals()
        .serviceBulletinClassifications()
        .filter((e) => {
          return e.value === item;
        });
    });
  }

  getSelectedStatus(val?: string[]) {
    return val?.flatMap((item) => {
      return this.filterSignals()
        .status()
        .filter((e) => {
          return e.value === item;
        });
    });
  }

  async getCategory(code: string) {
    if (this.allCategories().length === 0) {
      await this.getCategories();
    }
    const cat = this.allCategories().filter((e) => {
      return e.value === code;
    });
    return cat[0].name;
  }

  updateCategories(contentTypes?: Filter[]) {
    if (contentTypes?.length === 0) {
      this.filterSignals().categories.set(this.allCategories());
      return;
    }
    this.filterSignals().categories.update(() => {
      let categories: Filter[] = [];
      contentTypes?.map((contentType) => {
        return this.allCategories().map((category) => {
          if (Number(category.value) === contentType.categoryId) {
            categories = [...categories, category];
          }
        });
      });
      return [...new Set(categories)];
    });
  }

  async getAllFilters() {
    await Promise.all([
      this.getContentTypes(),
      this.getLanguages(),
      this.getBrands(),
      this.getCategories(),
      this.getServiceBulletinClassifications(),
    ]).finally(() => {
      this.hasFilterValuesLoaded.set(true);
    });

    return this.filterSignals();
  }

  getFilterValue(filter?: any) {
    if (!filter || !filter[0]?.code) {
      return filter;
    }

    return filter.map((item: { code: string; value: string; name: string }) => item.value);
  }
}
