import { Injectable, inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private authService = inject(MsalService);
  private msalBroadcastService = inject(MsalBroadcastService);
  private router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (state.url === '/' || state.url === '/login') {
      const activeAccount = this.authService.instance.getActiveAccount();
      if (activeAccount) {
        return of(true);
      } else {
        this.router.navigateByUrl('/login');
        return of(false);
      }
    }

    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      map(() => {
        const activeAccount = this.authService.instance.getActiveAccount();
        if (activeAccount) {
          return true;
        } else {
          this.authService.loginRedirect({
            scopes: ['user.read'],
            redirectStartPage: state.url,
          });
          return false;
        }
      }),
    );
  }
}
