import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ROLE_MYTOOLBOX_ADMINISTRATOR, RolesService } from '@my-toolbox-fe/feat-roles';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  private rolesService = inject(RolesService);
  private router = inject(Router);

  async canActivate() {
    const hasPermission = await this.rolesService.hasPermissions([ROLE_MYTOOLBOX_ADMINISTRATOR]);

    return hasPermission ? true : this.router.navigateByUrl('/');
  }
}
