import { computed, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@my-toolbox-fe/environments/environment';
import { catchError, firstValueFrom, throwError } from 'rxjs';
import { SearchService } from './search.service';
import { mapTags } from '../utils/helpers';
import { FiltersService } from './filters.service';
import { AlertService } from '@my-toolbox-fe/ui-elements';
import { SavedSearch } from '@my-toolbox-fe/type-search';
import { Pagination } from '@my-toolbox-fe/type-pagination';

import { Filter } from '@my-toolbox-fe/type-filter';

//TODO: Change to subscription feature?

@Injectable({
  providedIn: 'root',
})
export class SavedSearchService {
  #savedSearches: WritableSignal<SavedSearch[]> = signal([]);
  savedSearches = computed(this.#savedSearches);

  private http = inject(HttpClient);
  private searchService = inject(SearchService);
  private filtersService = inject(FiltersService);
  private alertService = inject(AlertService);

  #pagination = signal<Pagination>({
    currentPage: 0,
    pageSize: 0,
    sort: '',
    totalPages: 1,
    totalResults: 0,
  });
  pagination = computed(this.#pagination);

  propertyValues: WritableSignal<{ code: string; values: Filter[] }[]> = signal([]);

  async saveSearch(saveSearch: {
    name: string;
    frequency?: string;
    onBehalfOfEmails: string[] | null;
  }) {
    const url = saveSearch.frequency
      ? `${environment.baseUrl}/savedsearches?subscriptions=true`
      : `${environment.baseUrl}/savedsearches`;
    const frequency = saveSearch.frequency ? saveSearch.frequency : null;

    await firstValueFrom(
      this.http.post(url, {
        name: `${saveSearch.name}`,
        query: this.searchService.lastQuery(),
        frequency: frequency,
        onBehalfOfEmails: saveSearch.onBehalfOfEmails,
        queryContext: this.searchService.searchMode(),
      }),
    ).then(() =>
      this.alertService.open({
        message: 'Saved search saved',
        variant: 'success',
      }),
    );
  }

  async editSavedSearch(
    search: {
      name: string;
      frequency: string;
      onBehalfOfEmails: string[] | null;
    },
    row: { query?: string; id: number },
  ) {
    return await firstValueFrom(
      this.http
        .put(`${environment.baseUrl}/savedsearches/${row.id}`, {
          name: search.name,
          query: row.query,
          frequency: search.frequency,
          onBehalfOfEmails: search.onBehalfOfEmails,
        })
        .pipe(
          catchError((error: any) => {
            return throwError(() => error);
          }),
        ),
    ).then(() =>
      this.alertService.open({
        message: 'Saved search successfully edited',
        variant: 'success',
      }),
    );
  }

  async getSavedSearches(currentPage?: number) {
    let params = new HttpParams();

    if (currentPage) {
      params = params.append('currentPage', currentPage);
    }

    return await firstValueFrom(
      this.http.get(`${environment.baseUrl}/savedsearches`, { params }),
    ).then((data) => {
      const savedSearch = data as {
        results: any[];
        pagination: Pagination;
      };

      savedSearch.results = mapTags(savedSearch.results, this.propertyValues());
      this.#savedSearches.set(savedSearch.results);
      this.setPagination(savedSearch.pagination);
    });
  }

  async unsubscribeSavedSearch(i: number) {
    return await firstValueFrom(this.http.delete(`${environment.baseUrl}/savedsearches/${i}`)).then(
      () =>
        this.alertService.open({
          message: 'Saved search successfully deleted',
          variant: 'success',
        }),
    );
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  splitAndValidateEmails(emailString: string): boolean {
    const emails = emailString.split(';');
    return emails.every(this.validateEmail);
  }

  setPagination(value: Pagination) {
    this.#pagination.set(value);
  }
  async getPropertyValues() {
    return await firstValueFrom(
      this.http.get(`${environment.baseUrl}/properties?codes=type,category`),
    ).then((res) => {
      const result = res as { code: string; values: Filter[] }[];
      this.propertyValues.set(result);
    });
  }
}
