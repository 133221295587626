import { SEARCH_MODE, SearchedValues, Sorting } from '@my-toolbox-fe/type-search';
import { HttpParams } from '@angular/common/http';
import { Pagination } from '@my-toolbox-fe/type-pagination';
import { VisibleFacet } from '@my-toolbox-fe/type-filter';

export function buildQuery(
  searchValues?: SearchedValues,
  options?: {
    pagination?: Pagination;
    sorting?: Sorting;
    facets?: VisibleFacet[];
    searchMode?: string;
    isRefinedSearch?: boolean;
  },
) {
  if (!searchValues) {
    return;
  }

  let customQueryString = '';
  let queryParts: unknown[] = [];
  let params = new HttpParams();

  if (!options?.isRefinedSearch) {
    Object.entries(searchValues.filters).map(([, filters]) => {
      filters?.map((filter) => {
        if (filter && filter.code !== '') {
          const replaceValue = filter.value.split('_').join(' ');
          queryParts = [...queryParts, `:${filter.code}:${replaceValue}`];
        }
      });
    });
  }

  if (searchValues.freeTextSearch) {
    if (options && options.searchMode === SEARCH_MODE.SERIAL_NUMBER.value) {
      customQueryString = '';
    } else {
      customQueryString = `${searchValues.freeTextSearch}`;
    }
  }

  if (options?.facets && options.isRefinedSearch) {
    options.facets.map((facet: { code: string; value: string }) => {
      queryParts = [...queryParts, `:${facet.code}:${facet.value}`];
      queryParts = [...new Set(queryParts)];
    });
  }

  if (options?.sorting?.sortDirection) {
    customQueryString += `:${options.sorting.sortDirection}`;
  }

  if (queryParts.length) {
    customQueryString += `${queryParts.join('')}`;
  }

  if (options?.pagination) {
    params = params.append('currentPage', options?.pagination.currentPage);
  }

  if (customQueryString) {
    params = params.append('query', customQueryString);
  }

  if (options?.searchMode) {
    params = params.append('searchQueryContext', options.searchMode);
  }

  return params;
}
