import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  private authService = inject(MsalService);
  private router = inject(Router);

  async canActivate() {
    return this.authService.instance.getActiveAccount()
      ? this.router.navigateByUrl('/')
      : true;
  }
}
