import { Filter } from '@my-toolbox-fe/type-filter';
import { codesBrands } from '@my-toolbox-fe/type-search';

export function replaceSpaceWithUnderscore(value: string) {
  return value.replace(/ /g, '_');
}

export function replaceFilterValues(values: Filter[] | Filter | undefined) {
  if (!values) {
    return;
  }
  if (Array.isArray(values)) {
    return values.map((item) => {
      return {
        ...item,
        value: item.value.replace(/_/g, ' '),
      };
    });
  }

  return {
    ...values,
    value: values.value.replace(/_/g, ' '),
  };
}

export function replaceChar(value: string, char1: string, char2: string) {
  return value.replace(char1, char2);
}

export function joinValues(values: string[]) {
  return values.join(', ');
}

export function formatArrayValues(values: string[]) {
  return values.join(', ').replace(/_/g, ' ');
}

export const sortAlphabetically = (a: { value: string }, b: { value: string }) =>
  a.value.localeCompare(b.value);

export const sortOnNameAlphabetically = (a: { name: string }, b: { name: string }) =>
  a.name.localeCompare(b.name);

export const mapTags = (
  data: { query: string }[],
  propertyValues: { code: string; values: Filter[] }[],
) => {
  return data.map((result: { query: string }) => {
    const filters =
      result.query
        ?.toString()
        .replace(/:relevance-desc|:updated-desc/g, ' ')
        .split(':')
        .filter((val) => val !== '') || [];

    let tags: any[] = [];
    if (filters.length > 0) {
      filters.reduce((prev, curr, i) => {
        if (i === 1 && prev !== ' ') {
          tags = [{ freeTextSearch: prev }];
        }

        if (i % 2 === 0 && i !== 1) {
          tags = [...tags, { [prev]: replaceChar(curr, '_', ' ') }];
        }

        return curr;
      });
    }

    if (filters.length === 1) {
      tags = [{ freeTextSearch: filters[0] }];
    }

    const unigueTags = tags.filter((item, index, self) => {
      const jsonItem = JSON.stringify(item);
      return index === self.findIndex((other) => JSON.stringify(other) === jsonItem);
    });

    const mappedTags = unigueTags.map((tag) => {
      if (tag.type) {
        propertyValues[0].values.find((value: Filter) => value.value === tag);
      }

      if (tag.category) {
        const cat = propertyValues[1].values.find((value: Filter) => value.value === tag.category);
        tag.category = cat?.name;
      }

      if (tag.brand) {
        tag.brand = codesBrands[tag.brand];
      }

      return tag;
    });

    return {
      ...result,
      mappedTags,
    };
  });
};
