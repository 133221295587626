export const SEARCH_MODE = {
  DEFAULT: { value: 'DEFAULT', name: 'Free text' },
  SERIAL_NUMBER: { value: 'SERIAL_NUMBER', name: 'Serial number' },
  SERVICE_BULLETIN: {
    value: 'SERVICE_BULLETIN',
    name: 'Service bulletin',
    type: 'Service_Bulletin',
    category: '2',
  },
  //CHECKBOX FOR GLOBAL DOCUMENTS
  GLOBAL_USER: {
    code: 'asset_audience',
    name: 'Global',
    value: 'Global',
  },
  NOT_GLOBAL_USER: {
    code: '',
    name: '',
    value: '',
  },
};
